<template>
  <div class="repairDetail">
    <div class="contentView">
      <el-empty  class="contentMsg" v-if="tableData.length==0" description="暂无数据"></el-empty>
      <status-item-v2 v-for="(item, index) in tableData" :data-info="item"></status-item-v2>
    </div>
    <pagination-vue @current-change="currentChange"
                    :total-count="totalCount"
                    :page-size="pageSize"
                    :pager-count="5"
                    :current-page="page"
    ></pagination-vue>

  </div>
</template>

<script>
import DeviceShareStatus from '@/views/shareAndDispatch/device/deviceShareDetail/deviceStatus/deviceShareStatus'
import StatusItemV2 from '@/views/shareAndDispatch/device/deviceShareDetail/deviceStatus/statusItemV2'
import HStack from '@/components/hStack/hStack'
import PaginationVue from '@/components/pagination/paginationVue'

export default {
  name: 'repairDetail',
  components: { PaginationVue, HStack, StatusItemV2, DeviceShareStatus },
  props: {
    orgList: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectOrgList: {
      type: Array,
      default: () => {
        return []
      }
    },
    query: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      count: 0,
      dataQuery: {},
      page: 1,
      pageSize: 50,
      totalCount: 0,
      tableData: [],
      loading: false,
      loadingView: null
    }
  },
  watch: {
    query: {
      handler(value) {
        if (Object.keys(value).length == 0) {
          return
        }
        this.page = 1
        this.getData()
      },
      deep: true,
      immediate: true
    },
    loading: {
      handler(value) {
        if (value) {
          this.startLoading('deviceShareStatus')
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    pushDetail: function() {
      this.$push('deviceBorrow/deviceDetails', {})
    },
    currentChange: function(index) {
      this.page = index
      this.loadRecord = true
      this.getData()
    },
    getData() {
      let shareOrderListByStatus = () => {
        return new Promise(resolve => {
          let orderStatus = 4

          let query = {
            page: this.page,
            pageSize: this.pageSize,
            orderStatus: orderStatus
          }

          for (const queryKey in this.query) {
            query[queryKey] = this.query[queryKey]
          }

          this.$api.deviceOrg.shareOrderListByStatus(query).then(res => {
            let list = []
            if (this.$valueIsExist(res, 'data')) {
              list = res['data']
            }
            let totalCount = 0
            if (this.$valueIsExist(res, 'totalCount')) {
              totalCount = res['totalCount']
            }
            resolve({ result: true, list: list, totalCount: totalCount })
          }).catch(e => {
            resolve({ result: true, list: [], totalCount: 0 })
          })
        })
      }

      this.loading = true
      shareOrderListByStatus().then(res => {
        this.tableData = res.list
        this.totalCount = res.totalCount
        this.loading = false
      })

    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.repairDetail {

  width: 100%;
  height: 100%;

  .contentView {
    padding: 0px 10px 0px 10px;
    width: 100%;
    height: calc(100% - 80px);
    overflow: scroll;

    .contentMsg {
      width: 100%;
      height: 30px;
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      font-size: 25px;
      color: $color2;
      text-align: center;
    }

  }


}

</style>
